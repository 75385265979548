@media screen and (min-width: $breakpoint) {
    .layout-container {
        &.layout-static {
            .layout-sidebar {
                transition: transform var(--transition-duration);
            }

            .layout-content-wrapper {
                margin-left: 14rem;
            }
        }

        &.layout-static-inactive {
            .layout-sidebar {
                transform: translateX(-100%);
            }

            .layout-content-wrapper {
                margin-left: 0;
            }
        }

        &.layout-overlay {
            .layout-content-wrapper {
                margin-left: 0;
            }

            .layout-sidebar {
                z-index: 999;
                transform: translateX(-100%);
                transition: transform var(--transition-duration);
            }

            &.layout-overlay-active {
                .layout-sidebar {
                    transform: translateX(0);
                }
            }
        }
    }
}

@media screen and (max-width:($breakpoint - 1)) {
    .blocked-scroll {
        overflow: hidden;
    }

    .layout-topbar {
        height: 9rem;
        flex-wrap: wrap;
        padding: 1rem 1rem 0 1rem;

        .app-logo {
            span {
                display: none;
            }
        }

        .topbar-menubutton {
            display: flex;
            align-items: center;
            justify-content: center;
            position: fixed;
            bottom: 0;
            right: 0;
            width: 4.5rem;
            height: 4.5rem;
            margin: 0;
            border-top-right-radius: 0;
            border-bottom-left-radius: 0;
            border-top-left-radius: 30px;
            background: var(--primary-color);
            transition: background-color var(--transition-duration);

            &:hover {
                background-color: var(--primary-400);
            }

            span {
                top: 30px;
                left: 20px;
                background: var(--primary-color-text);

                &:before,
                &:after {
                    background: var(--primary-color-text);
                }
            }
        }

        .topbar-menu {
            order: 1;
            width: 100%;
            margin: 0;
        }

        .topbar-search {
            margin-left: auto;
        }

        .topbar-profile {
            margin-left: 1rem;

            .topbar-profile-button {

                .profile-details,
                .profile-name,
                i {
                    display: none;
                }
            }
        }
    }

    .layout-content-wrapper {
        margin-left: 0;
        padding-top: 9rem;
        height: calc(100% - 9rem);

        .layout-content {
            border-top-right-radius: 30px;
            height: calc(100vh - 9rem);
        }
    }

    .layout-sidebar {
        box-shadow: 0 0 1rem rgba(0, 0, 0, .15);
        transform: translateY(100%);
        width: 100%;
        top: 9rem;
        height: calc(100% - 9rem);
        mix-blend-mode: normal;
        backdrop-filter: blur(200px);
        border-radius: 30px 30px 0px 0px;
        transition: transform var(--transition-duration);
    }

    .layout-container {
        &.layout-mobile-active {
            .layout-topbar {
                .topbar-menubutton {
                    span {
                        width: 0;

                        &:before {
                            width: 2rem;
                            left: 0;
                            transform: translateY(9px) rotate(45deg);
                        }

                        &:after {
                            width: 2rem;
                            transform: translateY(-9px) rotate(-45deg);
                        }

                    }
                }
            }

            .layout-sidebar {
                transform: translateY(0);
            }
        }

        .layout-search {
            .search-container {
                width: 75vw;
            }
        }
    }
}

// @media screen and (min-width: 1960px) {
//     .landing-wrapper, .layout-content-inner {
//         width: 1504px;
//         margin-left: auto !important;
//         margin-right: auto !important;
//     }
// }