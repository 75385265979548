/* You can add global styles to this file, and also import other style files */
$gutter: 1rem; //for primeflex grid system

/* Layout */
@use "assets/layout/styles/layout/layout.scss";

/* PrimeNG */
// @import "../node_modules/primeng/resources/primeng.min.css";
@use "../node_modules/primeflex/primeflex.scss";
@use "../node_modules/primeicons/primeicons.css";

/* Quill Text Editor for p-editor */
@import "../node_modules/quill/dist/quill.core.css";
@import "../node_modules/quill/dist/quill.snow.css";

/* Demos */
@import "assets/demo/styles/flags/flags.css";
@import "assets/demo/styles/badges.scss";
@import "assets/demo/styles/code.scss";


.btn-right{
    float: right !important;
}

.content-center{
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}
.content-v-center{
    display: flex !important;
    align-items: center !important;
}

.p-button-edit-icon{
    margin-left: -7px !important;
    margin-top: -1px !important;
}
.p-paginator-current{
    height: auto !important;
}